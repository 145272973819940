import { Company } from '../../set-panel/model/company.model';
import { Set } from '../../set-panel/model/set-model/set.model';
import { LocationModel } from './location.model';

export class Customer {
  _id?: string;
  company?: Company;
  email: string;
  customerCompany?: { name: string; };
  name?: { first?: string; last?: string; };
  location?: LocationModel;
  requestContact?: {
    about: string[],
    message: string,
  };
  phone?: string;
  details?: CustomerDetails;
  BADGE_ID?: string;
  LEAD_RETRIEVAL_API_RESPONSE?: any;
}

export interface CustomerDetails {
  REGISTERED_IN_SET?: Set;
  CLIENT_DATA?: any;
  DROPPED_EMAIL?: string;
  BADGE_ID?: string;
  LEAD_RETRIEVAL_API_RESPONSE?: any;
}
