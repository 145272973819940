import { NGXLogger } from 'ngx-logger';
import { SetService } from '../../pages/set-panel/services/set.service';
import { Injectable } from '@angular/core';
import { ApiPostMessageService } from '../api-post-message.service';
import { Document } from '../../pages/set-panel/model/document.model';
import { DocumentPreview } from '../../pages/set-panel/enum/document-preview.enum';
import { VideoPreviewComponent } from '../../pages/set-panel/video-preview/video-preview.component';
import { PdfViewerComponent } from '../../pages/set-panel/pdf-viewer/pdf-viewer.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PreviewService {
  private docPreview: BsModalRef;
  private preview: DocumentPreview;
  public get isEnabled() {
    return this.preview
      && [
        DocumentPreview.enabled,
        DocumentPreview.optDisabled,
      ].includes(this.preview);
  }

  private openStatusSbj = new BehaviorSubject(false);
  public get onOpenStatusChanged() {
    return this.openStatusSbj.asObservable();
  }
  public get isOpen() {
    return this.openStatusSbj.value;
  }
  // TODO make private next prop
  public set isOpen(value: boolean) {
    this.openStatusSbj.next(value);
  }

  constructor(
    private readonly modal: BsModalService,
    private readonly logger: NGXLogger,
    private readonly setService: SetService,
    private readonly apiPostMessageService: ApiPostMessageService,
  ) { this.setListeners(); }

  private setListeners() {
    this.setService.onCurrentSetChanged
      .subscribe(() => {
        this.preview = this.setService.currentSet
          .config.documentPreview?.toUpperCase() as DocumentPreview;
      });
  }

  openPreview({ doc, page }: { doc: Document, page: number; }) {
    if (this.isOpen) { return; }
    this.docPreview = this.getModalByDocType({ doc, page });
    this.apiPostMessageService.emitAction({
      req: 'action-preview',
      status: 'open',
      item: doc._id,
      page,
    });
  }

  private getModalByDocType({ doc, page }: { doc: Document, page: number; }) {
    const opts: ModalOptions = {
      keyboard: false,
      backdrop: 'static',
      ignoreBackdropClick: true,
      class: 'modal-lg preview-modal-dialog',
      initialState: {
        document: doc.sourceUrl,
        optDisabled: this.preview === DocumentPreview.optDisabled,
        pdfPage: page,
      },
    };
    const mimeType = doc.file.details.mimeType;
    if (mimeType && mimeType.includes('video')) {
      return this.modal.show(VideoPreviewComponent, opts);
    }
    return this.modal.show(PdfViewerComponent, opts);
  }

  public closePreview() {
    this.isOpen = false;
    this.docPreview?.hide();
    this.apiPostMessageService.emitAction({
      req: 'action-preview',
      status: 'close',
    });
  }
}
