import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericPopupComponent } from './generic-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [GenericPopupComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
  ],
  exports: [
    GenericPopupComponent,
  ],
})
export class GenericPopupModule { }
