import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeOutPopupComponent } from './time-out-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    TimeOutPopupComponent,
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
  ],
  exports:[
    TimeOutPopupComponent,
  ]
})
export class TimeOutPopupModule { }
