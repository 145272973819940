import { EventEmitter, Injectable } from '@angular/core';
import { CartItemModel } from '../_shared/cart/cart-item.model';
import { Category } from '../pages/set-panel/model/category.model';
import { Document } from '../pages/set-panel/model/document.model';
import { Content } from '../pages/set-panel/model/content.model';
import { NGXLogger } from 'ngx-logger';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CartModel } from '../_shared/cart/cart.model';
import { TouchlessService } from './touchless-service/touchless.service';
import { ApiPostMessageService } from './api-post-message.service';
import { BaseService } from '../../_base/base.service';

@Injectable({
  providedIn: 'root'
})
export class CartService extends BaseService {
  private readonly apiPath = environment.baseUrl +
    (environment.usePort ?
      (':' + environment.port) : '') +
    '/' + environment.apiHead + 'cart';

  onCartAction = new EventEmitter<{ document: Document, category: Category; }>();
  cart: CartItemModel[] = [];
  cartDocuments: Document[] = [];
  // TODO Re factor document list component to not repeat for each category its own code (Not now because of short time)
  private _lastItemRemoved: string | undefined;
  private _lastItemAdded: string | undefined;
  content: Content[];

  constructor(
    logger: NGXLogger,
    private readonly http: HttpClient,
    private readonly touchlessService: TouchlessService,
    private readonly apiPostMessageService: ApiPostMessageService,
  ) {
    super(logger);
    touchlessService.onData().subscribe(this._onData.bind(this));
    touchlessService.onAction().subscribe(this._onAction.bind(this));
  }

  private _onData(params: any) {
    switch (params.req) {
      case 'current-cart': {
        return { res: this.cart };
      }
    }
  }

  private _onAction(params: any) {
    switch (params.req) {
    }
  }

  /**
   * Add item to an array for final cart items
   */
  addToCart(doc: Document, category: Category) {
    if (this._lastItemAdded === doc._id) { return; }
    if (this._lastItemRemoved === doc._id) {
      this._lastItemRemoved = undefined;
    }
    this._lastItemAdded = doc._id;
    const index = this.cart.findIndex(item => item.category._id === category._id);
    let docIndex = 0;
    // Enable cart property of the selected document
    if (index > -1) {
      docIndex = this.cart[index].documents.findIndex(iDoc => iDoc._id === doc._id);
      if (docIndex > -1) {
        this.cart[index].documents[docIndex].cart = true;
      }
    }
    // Add it to an array of all documents to know more quickly which is selected
    if (!this.isInCart(doc)) {
      this.cartDocuments.push(doc);
    }
    // Mark the document as added if it's in another category
    this.addDocsToOtherCategories(doc, category, this.content);

    // Add doc to cart in controller
    this.apiPostMessageService.emitAction({
      req: 'change-cart',
      item: {
        doc: {
          _id: doc._id,
          index: docIndex,
        }
      },
      do: 'add',
    });
  }

  /**
   * Remove an item from the cart items
   */
  removeFromCart(doc: Document, category: Category) {
    if (this._lastItemRemoved === doc._id) { return; }
    if (this._lastItemAdded === doc._id) {
      this._lastItemAdded = undefined;
    }
    this._lastItemRemoved = doc._id;
    const index = this.cart.findIndex(item => item.category._id === category._id);
    // Disable cart property of the selected document
    let docCartIndex = 0;
    if (index > -1) {
      docCartIndex = this.cart[index].documents.findIndex(cartDoc => cartDoc._id === doc._id);
      if (docCartIndex > -1) {
        this.cart[index].documents[docCartIndex].cart = false;
      }
    }
    // And check if the doc is in another category and disable it
    this.cart.forEach((cartItem, otherIndex) => {
      if (cartItem.category._id !== category._id) {
        const docCartIndex = cartItem.documents.findIndex(cartDoc => cartDoc._id === doc._id);
        if (docCartIndex > -1) {
          cartItem.documents[docCartIndex].cart = false;
        }
      }
    });
    // Finally remove it from the general list of documents
    const docIndex = this.cartDocuments.findIndex(cartDoc => cartDoc._id === doc._id);
    if (docIndex > -1) {
      this.cartDocuments.splice(docIndex, 1);
    }

    this.apiPostMessageService.emitAction({
      req: 'change-cart',
      item: {
        doc: {
          _id: doc._id,
          index: docCartIndex,
        }
      },
      do: 'remove',
    });
  }

  /**
   * Checks the categories of the content and the documents of each one to mark selected documents
   */
  addDocsToOtherCategories(doc: Document, category: Category, content: Content[]) {
    content.forEach(cont => {
      cont.documents.forEach(docInContent => {
        if (docInContent.document._id === doc._id && cont.category._id !== category._id) {
          const index = this.cart.findIndex(item => item.category._id === cont.category._id);
          if (index > -1) {
            const docIndex = this.cart[index].documents.findIndex(iDoc => iDoc._id === docInContent.document._id);
            this.cart[index].documents[docIndex].cart = true;
          }
        }
      });
    });
  }

  getCart() {
    // this return categories which have some selected document
    return this.cart.filter(c => c.documents.some(d => d.cart));
  }

  isInCart(doc: Document) {
    return this.cartDocuments?.some(cartDoc => cartDoc._id === doc._id);
  }

  getCartItemsQuantity() {
    return this.cartDocuments ? this.cartDocuments.length : 0;
  }

  setSetContent(content: Content[]) {
    if (this.cart.length === 0) {
      this.content = content;
      // only at start we save the content and complete the cart. This is for keep the categories and docs order.
      this.content.forEach(cont => {
        const docs = [];
        cont.documents.forEach(value => docs.push(value.document));
        this.cart.push({ category: cont.category, documents: docs });
      });
    }
  }

  checkout(finalCart: CartModel, companyId: string, hash: string, customerId?: string) {
    let kioskReference = companyId + ':' + hash;
    if (customerId) {
      kioskReference += ':' + customerId;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'kiosk-reference': btoa(kioskReference)
      })
    };

    return this.http.post<any>(
      this.apiPath, finalCart, httpOptions
    );
  }

  clearCart() {
    this.cart.forEach(cartI => {
      cartI.documents.forEach(doc => doc.cart = false);
    });
    this.cartDocuments = [];
  }
}
