<ng-template #template>
  <div
    id="modalBody"
    class="modal-body p-5"
    [ngStyle]="{ 'background-color': timeoutStyles.backgroundColor }"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h4 id="modalTitle" class="modal-title pull-left">
            Do you need more time?
          </h4>
          <h1 id="modalTime">
            {{ timeOutPopupService.currentTime }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mb-3">
          <button
            id="yesBtn"
            class="btn btn-success btn-lg next-btn w-100"
            [ngStyle]="{
              'background-color': timeoutStyles.yesBtnColor,
              color: timeoutStyles.fontColor
            }"
            (click)="onTimeNeed()"
          >
            Yes
          </button>
        </div>
        <div class="col-6">
          <button
            id="noBtn"
            class="btn btn-secondary btn-lg next-btn w-100"
            [ngStyle]="{
              'background-color': timeoutStyles.noBtnColor,
              color: timeoutStyles.fontColor
            }"
            (click)="onNoTimeNeed()"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
