import { Router, RouterEvent } from '@angular/router';
import { SetService } from './pages/set-panel/services/set.service';
import { catchError, takeWhile, tap, take, skipWhile, concatMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Set } from './pages/set-panel/model/set-model/set.model';
import { CartService } from './_services/cart.service';
import { CustomerService } from './pages/customer-data/services/customer.service';
import { Category } from './pages/set-panel/model/category.model';
import { Document } from './pages/set-panel/model/document.model';
import AppRoutes from './_type/app-routes.enum';
import parseUrl from './_shared/parse-url';

export class SetLoaderHandler {
  constructor(
    private readonly customer: CustomerService,
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly setService: SetService,
    private readonly cartService: CartService,
  ) { }

  public loadSet() {
    let setResolved = false;
    return this.router.events.pipe(
      takeWhile(() => !setResolved),
      skipWhile((e: RouterEvent) => !e?.url),
      concatMap((e: RouterEvent) => {
        setResolved = true;
        const {
          setId, companyId, setFriendlyUrl,
        } = parseUrl(e.url);
        if (setId) {
          return this.loadById(setId);
        } else {
          return this.loadByFriendlyUrl(companyId, setFriendlyUrl);
        }
      }), take(1));
  }

  private loadByFriendlyUrl(companyId: string, friendlyUrl: string) {
    return this.setService
      .getByUrl(`${companyId}:${friendlyUrl}`)
      .pipe(
        concatMap(this.onGetSetByUrl.bind(this)),
      );
  }

  private loadById(setId: string) {
    return this.setService.getSet(setId).pipe(
      catchError((err: HttpErrorResponse) => {
        this.logger.error(
          'Error in get set with query params: ',
          err.message
        );
        // TODO review why use a 2nd call including jwt token. which token? from where?
        return this.setService.getSet(setId, true);
      }),
      concatMap(this.onGetSet.bind(this)),
    );
  }

  private async onGetSet(set: Set) {
    if (set.config.friendlyUrl) {
      await this.router.navigate([set.config.friendlyUrl]);
    }
    this.loadAndProcessSet(set);
  }

  private onGetSetByUrl(resWithId: any) {
    return this.setService
      .getSet(resWithId.data[0]?._id).pipe(
        catchError((err: HttpErrorResponse) => {
          this.logger.error('Error in get set with friendly url: ', err.message);
          return this.setService
            .getSet(resWithId.data[0]?._id, true);
        }),
        tap(this.loadAndProcessSet.bind(this)),
      );
  }

  private loadAndProcessSet(set: Set) {
    try {
      this.setService.setSet(set);
      this.cartService.setSetContent(set.content);

      // process inputs to show on customer data form
      if (set.customerForm?.enabled
        && set.customerForm?.fields?.length > 0) {
        this.customer.processFormData(set.customerForm?.fields);
      }

      this.cartService.onCartAction
        .subscribe(this.onCartAction.bind(this));

      this.navigateToSetConfig1stPage(set);
    } catch (e) { this.logger.error(e); }
  }

  private onCartAction(cartItem: {
    document: Document;
    category: Category;
  }) {
    try {
      if (cartItem.document.cart) {
        this.cartService.addToCart(cartItem.document, cartItem.category);
      } else {
        this.cartService.removeFromCart(cartItem.document, cartItem.category);
      }
    } catch (e) { this.logger.error(e); }
  }

  private navigateToSetConfig1stPage(set: Set) {
    const navCmds = [];
    if (set.config?.friendlyUrl) {
      navCmds.push(set.config.friendlyUrl);
    }
    if (set.welcomePage?.enabled) {
      navCmds.push(AppRoutes.welcome);
      this.router.navigate(navCmds);
    } else if (set?.customerForm?.enabled) {
      navCmds.push(AppRoutes.data);
      this.router.navigate(navCmds);
    } else {
      navCmds.push(AppRoutes.set);
      this.router.navigate(navCmds);
    }
  }
}
