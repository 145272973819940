import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../../../_type/api-response.type';
import { Customer } from '../model/customer.model';
import { tap } from 'rxjs/operators';
import { CustomerFormModel } from '../model/customer-form.model';
import { ICustomerField } from '../interfaces/customer-field.interface';
import { BaseService } from '../../../../_base/base.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseService {
  private readonly apiPath = environment.baseUrl +
    (environment.usePort ?
      (':' + environment.port) : '') +
    '/' + environment.apiHead + 'customer';

  private customerSource = new BehaviorSubject(new Customer());
  actualCustomer = this.customerSource.asObservable();
  savedCustomer: Customer;

  private _fieldsToShow: CustomerFormModel = new CustomerFormModel(true);

  constructor(
    logger: NGXLogger,
    private readonly http: HttpClient,
  ) { super(logger); }

  private setCustomer(customer: Customer) {
    this.savedCustomer = {
      ...this.savedCustomer,
      ...customer,
    };
  }

  saveCustomerId(id: string) {
    this.savedCustomer._id = id;
  }

  get getActualCustomer() {
    return this.savedCustomer;
  }

  clearCustomer() {
    this.savedCustomer = undefined;
  }

  createCustomer(customer: Customer) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'kiosk-reference': btoa(customer.company._id + ':' + customer.details.REGISTERED_IN_SET.details.UNIQUE_HASH)
      })
    };
    this.setCustomer(customer);
    delete (customer.company);
    delete (customer.details);
    return this.http.post<ApiResponse<{ _id: string; }>>(
      this.apiPath, customer, httpOptions
    );
  }

  updateCustomer(customer: Customer) {
    const customerId = this.savedCustomer._id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'kiosk-reference': btoa(customer.company._id + ':' + customer.details.REGISTERED_IN_SET.details.UNIQUE_HASH + ':' + customerId)
      })
    };
    this.setCustomer(customer);
    delete (customer.company);
    delete (customer.details);
    return this.http.put<ApiResponse<{ _id: string; }>>(
      this.apiPath + '/' + customerId, customer, httpOptions
    ).pipe(
      tap(updated => { this.saveCustomerId(customerId); })
    );
  }

  processFormData(fieldsToShow: ICustomerField[]) {
    this._fieldsToShow = new CustomerFormModel(false);
    for (let field of fieldsToShow) {
      this._fieldsToShow[field.formControlName].enabled = true;
      this._fieldsToShow[field.formControlName].isRequired = field.isRequired;
      if (field.label) {
        this._fieldsToShow[field.formControlName].label = field.label;
      }
    }
  }

  public getFieldsToShow(): CustomerFormModel {
    return this._fieldsToShow;
  }

}
