export const environment = {
  production: false,
  name: 'dev',
  baseUrl: 'https://dev-api.cleverlit.com',
  touchlessParentOrigin: 'https://dev-kiosk.touchlesspanel.com',
  port: 3000,
  apiHead: 'v1/',
  includePortOnLinks: false,
  usePort: false,
  timeOutSeconds: 180,
  chatUrl: 'https://dev-chat.cleverlit.com/',
};
