import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GenericPopupService } from './_services/generic-popup.service';

@Component({
  selector: 'app-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.sass'],
})
export class GenericPopupComponent implements OnInit {
  modalRef?: BsModalRef;
  @ViewChild('generic')
  public template: HTMLTemplateElement;
  constructor(
    private readonly modalService: BsModalService,
    private genericPopUpService: GenericPopupService,
  ) {}
  ngOnInit() {
    this.genericPopUpService.openModalSubject.subscribe((src: string) => {
        this.openModal(src);
    });
  }
  openModal(src: string) {
      this.modalRef = this.modalService.show(this.template, {
        class: 'modal-dialog-centered full_popup',
      });
      let x = document.querySelector('.modal-content');
      x.querySelector('iframe').src = src;
  }


  closeModal() {
    this.modalRef.hide();
  }
}
