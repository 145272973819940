import { AllAppRoutes } from '../_type/app-routes.enum';

function parseUrl(url: string): {
  setId?: string,
  companyId?: string,
  setFriendlyUrl?: string,
} {
  const parts = url.split('/');
  switch (parts.length) {
    // originalUrl is one of the next options, without the <domain> part:
    // opt1 => <domain>/:id
    case 2: return { setId: parts[1] };
    // opt2 => <domain>/:companyId/:friendlyUrl
    case 3: return {
      companyId: parts[1],
      setFriendlyUrl: parts[2],
    };
    case 0:
    case 1: {
      const msg = 'Invalid url. Set panel configuration not found. Please contact us.';
      console.warn(msg);
      alert(msg);
      return {};
    }
    default:
      return handleUrlWithMoreThanFourParts(parts, url);
  }
}
function handleUrlWithMoreThanFourParts(parts: string[], url: string) {
  const AllRoutes: string[] = [];
  for (const route of AllAppRoutes()) {
    AllRoutes.push(...route
      .trim().replace(':', '').split('/')
    );
  }
  let setId: string;
  let companyId: string;
  let setFriendlyUrl: string;
  for (const part of parts) {
    if (!part.trim().length) { continue; }
    if (AllRoutes.includes(part)) { break; }
    if (!setId || !companyId) {
      setId = companyId = part;
    } else if (!setFriendlyUrl) {
      setFriendlyUrl = part;
      break;
    }
  }
  if (setFriendlyUrl) {
    return { companyId, setFriendlyUrl };
  } else {
    return { setId };
  }
}
export default parseUrl;
