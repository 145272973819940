import { NGXLogger } from 'ngx-logger';
import { SetService } from '../../pages/set-panel/services/set.service';
import { Injectable } from '@angular/core';
import { ChatbotServiceConfig } from '../../pages/set-panel/model/set-model/chatbot-service.config';
import { BehaviorSubject } from 'rxjs';
import ChatbotStatus from './chatbot-status';

@Injectable({ providedIn: 'root' })
export class ChatbotService {
  private config: ChatbotServiceConfig;

  private statusSbj = new BehaviorSubject(ChatbotStatus.disabled);
  public get onStatusChanged() {
    return this.statusSbj.asObservable();
  }
  public get status() {
    return this.statusSbj.value;
  }
  public get isVisible() {
    return this.statusSbj.value === ChatbotStatus.visible;
  }

  // TODO tmp props
  private iframeSbj: BehaviorSubject<{
    width: number,
    height: number,
    top: string,
    left: string,
    bottom: string,
    right: string,
  } | undefined> = new BehaviorSubject(undefined);
  public get iframe() {
    return this.iframeSbj.value;
  }

  constructor(
    private readonly logger: NGXLogger,
    private readonly setService: SetService,
  ) { this.setSubscription(); }

  private setSubscription() {
    const subs = this.setService.onCurrentSetChanged
      .subscribe(set => {
        subs?.unsubscribe();
        this.config = set?.chatbotService;
        this.switchTo(ChatbotStatus.enabled);
      });
  }

  public switchTo(status: ChatbotStatus) {
    if (!this.config || !this.config?.enabled) { return; }
    switch (status) {
      case ChatbotStatus.disabled:
      case ChatbotStatus.deActivated: {
        break;
      }
      case ChatbotStatus.visible: {
        this.setVisibleStyle();
        break;
      }
      default: {
        this.setHiddenStyle();
        break;
      }
    }
    this.statusSbj.next(status);
  }

  private setVisibleStyle() {
    this.iframeSbj.next({
      width: window.screen.availWidth * 0.75,
      height: window.screen.availHeight * 0.75,
      top: '0',
      left: '0',
      bottom: 'unset', right: 'unset',
    });
  }

  private setHiddenStyle() {
    // note: next 2 values are the size of the btn in the cl-chatbot project that opens/hides the chat
    const width = 115;
    const height = 45;
    this.iframeSbj.next({
      width, height,
      top: '1%', left: 'unset',
      bottom: 'unset', right: '1%',
    });
  }
}
