<router-outlet></router-outlet>
<app-touchless-qrs></app-touchless-qrs>
<app-time-out-popup></app-time-out-popup>
<app-generic-popup></app-generic-popup>
<!-- TODO move chatbot to a component -->
<div *ngIf="chatbot.status > CHATBOT_STATUS.deActivated" class="position-absolute chatbot-z-index m-0 p-0" [ngStyle]="{
  width: chatbot.iframe.width + 'px',
  height: chatbot.iframe.height + 'px',
  top: chatbot.iframe.top,
  left: chatbot.iframe.left,
  bottom: chatbot.iframe.bottom,
  right: chatbot.iframe.right
}" [ngClass]="{
  'bkg-color-black-transparent': chatbot.isVisible,
  'w-100': chatbot.isVisible,
  'h-100': chatbot.isVisible,
  'd-flex': chatbot.isVisible,
  'justify-content-center': chatbot.isVisible,
  'align-items-center': chatbot.isVisible
}">
  <iframe #chatbotIframe [src]="CHAT_URL" [width]="chatbot.iframe.width" [height]="chatbot.iframe.height" class="border-0" (load)="onChatbotIframeLoaded()"></iframe>
</div>
