enum ChatbotStatus {
  disabled, // set.chatbot-service.enabled = false
  enabled, // set.chatbot-service.enabled = true
  deActivated, // chatbot iframe was disabled
  activated, // chatbot iframe was enabled
  loaded, // chatbot iframe loaded
  hidden, // chatbot iframe contains an "open btn"
  visible, // chatbot iframe contains a "close btn"
}
export default ChatbotStatus;
