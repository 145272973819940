import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { SetService } from 'src/app/pages/set-panel/services/set.service';
import { QrPositionEnum } from 'src/app/_enums/qr.positions.enum';
import { QrCodeHandlerService } from 'src/app/_services/qr-code-handler.service';
import { TouchlessService } from 'src/app/_services/touchless-service/touchless.service';

@Component({
  selector: 'app-touchless-qrs',
  templateUrl: './touchless-qrs.component.html',
  styleUrls: ['./touchless-qrs.component.sass'],
})
export class TouchlessQrsComponent implements OnInit {
  constructor(
    public readonly qrCodeHandlerService: QrCodeHandlerService,
    private readonly touchlessService: TouchlessService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly setService: SetService,
    private readonly logger: NGXLogger
  ) { }
  resetBtnWithinTouchless!: boolean;

  ngOnInit(): void {
    this.setService.onCurrentSetChanged
      .subscribe({
        next: set =>
          (this.resetBtnWithinTouchless = set.config?.resetPanelBtn),
        error: (err) => this.logger.info(err),
      });
  }

  get canShowResetBtn(): boolean {
    return (
      (this.qrCodeHandlerService.endSessionQr.show == 'RESET_BTN' ||
        this.qrCodeHandlerService.endSessionQr.show == 'BOTH') &&
      !this.router.url.includes('welcome')
    );
  }

  get canShowResetBtnWithinTouchless(): boolean {
    return (
      !this.canShowResetBtn &&
      !this.canShowEndSessionQr &&
      !this.router.url.includes('welcome')
    );
  }

  get canShowEndSessionQr(): boolean {
    return (
      this._checkComponent(this.qrCodeHandlerService.endSessionQr.position) &&
      (this.qrCodeHandlerService.endSessionQr.show == 'QR_CODE' ||
        this.qrCodeHandlerService.endSessionQr.show == 'BOTH' ||
        this.qrCodeHandlerService.endSessionQr.show == 'QR_CODE_WITH_RESET') &&
      this.qrCodeHandlerService.isControllerConnected
    );
  }

  get canShowEndSessionQrAndReset(): boolean {
    return this.qrCodeHandlerService.endSessionQr.show == 'QR_CODE_WITH_RESET';
  }

  get canShowStartSessionQr(): boolean {
    return (
      this._checkComponent(this.qrCodeHandlerService.startSessionQr.position) &&
      this.qrCodeHandlerService.startSessionQr.show &&
      !this.qrCodeHandlerService.isControllerConnected &&
      !this.route.snapshot.queryParams.set // If not updating personal customer data
    );
  }
  /**
   * Check if the current component is available for the qr
   */
  private _checkComponent(qrPosition: QrPositionEnum) {
    switch (qrPosition) {
      case 'CUSTOMER_FORM': {
        if (this.router.url.includes('data')) {
          return true;
        }
        break;
      }
      case 'WELCOME_PAGE': {
        if (this.router.url.includes('welcome')) {
          return true;
        }
        break;
      }
    }

    return false;
  }

  endSession() {
    this.touchlessService.endSession();
  }
}
