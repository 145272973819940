export class CustomerFormModel {
  firstName = { enabled: false, isRequired: false, label: 'First Name' };
  lastName = { enabled: false, isRequired: false, label: 'Last Name' };
  companyName = { enabled: false, isRequired: false, label: 'Company Name' };
  telephoneNumber = { enabled: false, isRequired: false, label: 'Telephone Number' };
  address1 = { enabled: false, isRequired: false, label: 'Address Line 1' };
  address2 = { enabled: false, isRequired: false, label: 'Address Line 2' };
  city = { enabled: false, isRequired: false, label: 'City' };
  state = { enabled: false, isRequired: false, label: 'State' };
  zipCode = { enabled: false, isRequired: false, label: 'Zip Code' };
  country = { enabled: false, isRequired: false, label: 'Country' };
  contact = { enabled: false, label: 'Contact Amgen' };
  inquiry = { enabled: false, label: '' };

  constructor(defaultValue: boolean) {
    this.useDefaultValues(defaultValue);
  }

  /**
   * Default values for all other cleverlit sets
   */
  useDefaultValues(defaultValue: boolean) {
    this.firstName.enabled = defaultValue;
    this.lastName.enabled = defaultValue;
    this.companyName.enabled = defaultValue;
    this.telephoneNumber.enabled = defaultValue;
    this.address1.enabled = defaultValue;
    this.address2.enabled = defaultValue;
    this.city.enabled = defaultValue;
    this.state.enabled = defaultValue;
    this.zipCode.enabled = defaultValue;
    this.country.enabled = defaultValue;
    this.contact.enabled = false;
    this.inquiry.enabled = false;
  }
}
