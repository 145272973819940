import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import ChatbotCommAction from './chatbot-comm-action.type';
import { ChatbotService } from './chatbot.service';
import ChatbotStatus from './chatbot-status';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { skipWhile, skip } from 'rxjs/operators';
import { PreviewService } from '../preview-service/preview.service';
import { PreviewDocDto } from './preview-doc.dto';
import { PreviewDocActionHandler } from './preview-doc-action.handler';
import { SetService } from '../../pages/set-panel/services/set.service';

@Injectable({ providedIn: 'root' })
export class ChatbotCommService {
  private chatbotIframeSbj = new BehaviorSubject<HTMLIFrameElement | undefined>(undefined);
  public get onChatbotIframeChanged() {
    return this.chatbotIframeSbj.asObservable();
  }
  public get chatbotIframe() {
    return this.chatbotIframeSbj.value;
  }
  public set chatbotIframe(value: HTMLIFrameElement) {
    this.chatbotIframeSbj.next(value);
  }

  private previewDocActionHandler: PreviewDocActionHandler;

  constructor(
    private readonly logger: NGXLogger,
    private readonly chatbotService: ChatbotService,
    private readonly preview: PreviewService,
    private readonly setService: SetService,
  ) {
    const subs = this.setService.onCurrentSetChanged
      .subscribe(() => {
        subs.unsubscribe();
        if (chatbotService.status < ChatbotStatus.enabled) { return; }
        this.setUp();
      });
  }

  private setUp() {
    this.previewDocActionHandler = new PreviewDocActionHandler(
      this.logger,
      this.preview,
      this.setService,
    );
    this.setListeners();
  }

  private setListeners() {
    this.setChatbotIframeListener();
    this.setPreviewOpenStatusListener();
  }

  private setPreviewOpenStatusListener() {
    this.preview.onOpenStatusChanged
      .pipe(skip(1))
      .subscribe(isOpen => {
        if (!this.previewDocActionHandler.previewOpenByThisHandler
          || isOpen
        ) { return; }
        this.previewDocActionHandler.previewOpenedClosed();
        this.chatbotService.switchTo(ChatbotStatus.visible);
        this.sendMsg(ChatbotCommAction.previewClosed);
      });
  }

  private setChatbotIframeListener() {
    window.addEventListener('message', this.postMessageListener.bind(this));
    this.logger.log('Start listening chatbot...');
  }

  private postMessageListener(event: MessageEvent) {
    if (event.origin === location.origin) { return; }
    const { action, data } = event.data;
    this.logger.log(`Received msg from origin ${event.origin}`, { action, data });
    this.executeReceivedAction(action, data);
  }

  private executeReceivedAction(action: ChatbotCommAction, data: any) {
    switch (action) {
      case ChatbotCommAction.initialized: {
        this.chatbotService.switchTo(ChatbotStatus.loaded);
        return;
      }
      case ChatbotCommAction.chatOpened: {
        this.chatOpenedHandler();
        return;
      }
      case ChatbotCommAction.chatHidden: {
        this.chatbotService.switchTo(ChatbotStatus.hidden);
        return;
      }
      case ChatbotCommAction.previewDoc: {
        this.chatbotService.switchTo(ChatbotStatus.hidden);
        this.previewDocActionHandler.open(data as PreviewDocDto);
        return;
      }
    }
  }

  private chatOpenedHandler() {
    if (this.preview.isOpen) {
      this.preview.closePreview();
    }
    this.chatbotService.switchTo(ChatbotStatus.visible);
  }

  public sendAction(action: ChatbotCommAction, data?: any) {
    if (!this.chatbotIframe?.contentWindow) {
      this.handleWaitForIframeDefined(action, data);
      return;
    }
    this.sendMsg(action, data);
  }

  private handleWaitForIframeDefined(action: ChatbotCommAction, data?: any) {
    this.logger.warn('Chatbot iframe not found. Waiting for it...');
    const subs = this.onChatbotIframeChanged
      .pipe(skipWhile(iframe => !iframe))
      .subscribe(() => {
        subs.unsubscribe();
        this.sendMsg(action, data);
      });
  }

  private sendMsg(action: ChatbotCommAction, data?: any) {
    this.chatbotIframe.contentWindow.postMessage({ action, data }, environment.chatUrl);
    this.logger.log(`Sent msg for ${action}`, data);
  }
}
