import { NGXLogger } from 'ngx-logger';
import { BaseClass } from './base.class';
export abstract class BaseService extends BaseClass {
  // tslint:disable-next-line: variable-name
  private _inMemoryData: {
    data: any,
    date: number,
  }[] = [];
  protected readonly MAX_MS_IN_MEMORY = 1000 /*ms*/ * 60 /*sc*/ * 5 /*min*/;
  constructor(
    logger: NGXLogger,
  ) { super(logger); }
  /**
   * 1- **_inMemory()**: delete all in memory data.
   *
   * 2- **_inMemory(someKey)**: returns
   * - `undefined` if no exists data in memory with key `someKey` or data is outdated (ie. `now - savedTime > MAX_MS_IN_MEMORY`)
   * - otherwise, saved data
   *
   * 3- **_inMemory(someKey,someValue)**: save someValue with specific `someKey`, and returns
   * - `undefined` if no exists data in memory with key `someKey` or data is outdated (ie. `now - savedTime > MAX_MS_IN_MEMORY`)
   * - otherwise, outSaved data
   */
  protected _inMemory<T>(key?: keyof this, value?: T): T {
    if (key) {
      return this._onGetSetByKey<T>(key, value);
    } else {
      return this._onDeleteAll();
    }
  }
  private _onDeleteAll() {
    delete (this._inMemoryData);
    this._inMemoryData = [];
    return undefined;
  }
  private _onGetSetByKey<T>(key, value): T {
    const actualValue: { data: any, date: number; } = this._inMemoryData[key.toString()];
    let _toReturn: T;
    if (actualValue) {
      if (Date.now() - actualValue.date > this.MAX_MS_IN_MEMORY) {
        this._onOutdatedData(key);
      } else {
        _toReturn = actualValue.data as T;
      }
    }
    if (value !== undefined) {
      this._onNewValue(key, value);
    }
    return _toReturn;
  }
  private _onNewValue(key, value) {
    this._inMemoryData[key.toString()] = {
      data: value,
      date: Date.now(),
    };
  }
  private _onOutdatedData(key) {
    delete (this._inMemoryData[key.toString()]);
  }
}
