<div *ngIf="canShowResetBtn" class="floating-btn-unlink" (click)="endSession()">
  Reset
</div>
<div
  *ngIf="
    canShowResetBtnWithinTouchless &&
    resetBtnWithinTouchless &&
    resetBtnWithinTouchless === true
  "
  class="floating-btn-unlink"
  (click)="endSession()"
>
  Reset
</div>

<div *ngIf="canShowEndSessionQr" class="scanning-qr">
  <h5>
    SCAN QR CODE FOR <br />
    RESET SESSION
  </h5>

  <img
    *ngIf="!canShowEndSessionQrAndReset"
    [src]="qrCodeHandlerService.startSessionQr.src"
    alt="reset btn not included"
    width="200"
  />

  <img
    *ngIf="canShowEndSessionQrAndReset"
    [src]="qrCodeHandlerService.startSessionQr.src"
    alt="reset btn included"
    width="200"
  />
</div>

<div *ngIf="canShowStartSessionQr" class="scanning-qr">
  <h5>
    SCAN QR CODE FOR <br />
    TOUCHLESS OPTION
  </h5>
  <img [src]="qrCodeHandlerService.startSessionQr.src" width="200" />
</div>
