<ng-template #generic>
  <div class="modal-body p-5">
    <div class="container modal-body-popup">
      <div class="row modal-row-popup">
        <div class="col-12">
            <iframe id="iframe_id" height="100%" width="100%"></iframe>
          </div>
        </div>
        <div class="row close-button">
          <button class="close" (click)="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </ng-template>