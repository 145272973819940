<div class="popup">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Preview</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="height: 795px;">
      <div class="m-auto" style="width: 90%;">
        <ngx-spinner bdColor="#e9e9e9" size="medium" color="#015fa2" type="ball-pulse" [fullScreen]="false"></ngx-spinner>
        <video (canplay)="onVideoLoaded()" width="100%" autoplay loop [src]="videoUrl"></video>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-block align-self-end btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
    </div>
  </div>
  