import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { TouchlessService } from './_services/touchless-service/touchless.service';
import { QueryParamsService } from './_services/query-params.service';
import { ChatbotService } from './_services/chatbot-service/chatbot.service';
import { environment } from '../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import ChatbotStatus from './_services/chatbot-service/chatbot-status';
import { ChatbotCommService } from './_services/chatbot-service/chatbot-comm.service';
import { NGXLogger } from 'ngx-logger';
import { PreviewService } from './_services/preview-service/preview.service';
import { SetService } from './pages/set-panel/services/set.service';
import { SetLoaderHandler } from './set-loader-handler';
import { CustomerService } from './pages/customer-data/services/customer.service';
import { Router } from '@angular/router';
import { CartService } from './_services/cart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  @ViewChild('chatbotIframe')
  private readonly chatbotIframe!: ElementRef<HTMLIFrameElement>;
  public readonly CHATBOT_STATUS = ChatbotStatus;
  public readonly CHAT_URL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chatUrl);
  title = 'cl-kiosk';

  constructor(
    private readonly customer: CustomerService,
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly setService: SetService,
    private readonly cartService: CartService,
    protected readonly sanitizer: DomSanitizer,
    private readonly swUpdate: SwUpdate,
    private readonly toastr: ToastrService,
    private readonly touchlessService: TouchlessService,
    private readonly chatbotComm: ChatbotCommService,
    // not remove below services. the services are instantiated here
    private readonly queryParamsServ: QueryParamsService,
    public readonly chatbot: ChatbotService,
    private readonly preview: PreviewService,
  ) { }

  async ngOnInit() {
    // TODO review next class and maybe change it to APP_INITIALIZER
    await new SetLoaderHandler(
      this.customer,
      this.router,
      this.logger,
      this.setService,
      this.cartService,
    ).loadSet().toPromise();
    this.setUpdateListener();
  }

  private setUpdateListener() {
    this.swUpdate.available.subscribe(() => {
      this.toastr.info('Tap to reload', 'New version available.', {
        enableHtml: true,
        closeButton: true,
        newestOnTop: true,
        tapToDismiss: false,
        disableTimeOut: true,
        positionClass: 'toast-bottom-right'
      }).onTap.subscribe(tap => {
        this.touchlessService.endSession();
        window.location.reload();
      });
    });
  }

  onChatbotIframeLoaded() {
    this.chatbotComm.chatbotIframe = this.chatbotIframe?.nativeElement;
  }
}
