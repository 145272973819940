<div class="popup">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Preview</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-spinner *ngIf="!isLoaded" bdColor="#e9e9e9" size="medium" color="#015fa2" type="ball-pulse" [fullScreen]="false"></ngx-spinner>
    <ngx-extended-pdf-viewer [src]="pdfUrl" height="76vh" [page]="pdfPage" [showBookmarkButton]="false" [showPrintButton]="false" [showDownloadButton]="false" [showRotateButton]="false" [showSidebarButton]="false" [showOpenFileButton]="false" [showBorders]="false" [contextMenuAllowed]="false" [zoom]="'page-fit'" [useBrowserLocale]="true" (pdfLoaded)="pdfLoaded()" (pdfLoadingFailed)="errorPdf()" [customToolbar]="optDisabled ? hiddenToolbar : undefined">
    </ngx-extended-pdf-viewer>
    <ng-template #hiddenToolbar>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-block align-self-end btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
  </div>
</div>
