import { Component, OnInit } from '@angular/core';
import { SetService } from '../services/set.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { PreviewService } from '../../../_services/preview-service/preview.service';

@Component({
  selector: 'app-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.sass']
})
export class VideoPreviewComponent implements OnInit {
  public videoUrl: string;
  public document: string;
  public optDisabled: boolean;
  constructor(
    private readonly setService: SetService,
    private readonly bsModalRef: BsModalRef,
    private readonly spinner: NgxSpinnerService,
    private readonly preview: PreviewService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.setService.getDocumentPreviewUrl(this.document)
      .subscribe(response => {
        this.videoUrl = response.data;
        this.preview.isOpen = true;
      });
  }
  onVideoLoaded() {
    this.spinner.hide();
  }
  close() {
    this.bsModalRef.hide();
    this.preview.isOpen = false;
  }
}
