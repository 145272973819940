import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VirtualKeyboardService {

  private _enabled: boolean = false;
  public openVirtualKeyboard = new EventEmitter<{ _elId: string; top: string; bottom: string; }>();

  constructor() { }

  getState() {
    return this._enabled;
  }

  setState(newState: boolean) {
    this._enabled = newState;
  }

}
