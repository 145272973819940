import { PreviewDocDto } from './preview-doc.dto';
import { PreviewService } from '../preview-service/preview.service';
import { SetService } from '../../pages/set-panel/services/set.service';
import { Document } from '../../pages/set-panel/model/document.model';
import { NGXLogger } from 'ngx-logger';

export class PreviewDocActionHandler {
  private _previewOpenByThisHandler;
  public get previewOpenByThisHandler() {
    return this._previewOpenByThisHandler;
  }

  constructor(
    private readonly logger: NGXLogger,
    private readonly preview: PreviewService,
    private readonly setService: SetService,
  ) { }

  public open(dto: PreviewDocDto) {
    if (this.preview.isOpen) {
      const subs = this.preview.onOpenStatusChanged
        .subscribe(status => {
          if (status) { return; }
          subs.unsubscribe();
          this.proceedOpenPreview(dto);
        });
      this.preview.closePreview();
      return;
    }
    this.proceedOpenPreview(dto);
  }

  private proceedOpenPreview(dto: PreviewDocDto) {
    const doc: Document = this.findDoc(dto);
    this._previewOpenByThisHandler = true;
    this.preview.openPreview({
      doc, page: dto.page + 1,
    });
  }

  private findDoc(dto: PreviewDocDto) {
    const content = this.setService.currentSet.content;
    for (const { category, documents } of content) {
      for (const { document, previewable } of documents) {
        if (document._id === dto.doc) {
          return document;
        }
      }
    }
    this.logger.error(`Document ${dto.doc} not found`);
  }

  public previewOpenedClosed() {
    this._previewOpenByThisHandler = false;
  }
}
