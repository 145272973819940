import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SetService } from './../services/set.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiPostMessageService } from 'src/app/_services/api-post-message.service';
import { NGXLogger } from 'ngx-logger';
import { TouchlessService } from 'src/app/_services/touchless-service/touchless.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PreviewService } from '../../../_services/preview-service/preview.service';
import PreviewStatus from '../../../_type/preview-status.type';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.sass']
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  private statusSbj = new BehaviorSubject(PreviewStatus.none);
  public get status() {
    return this.statusSbj.value;
  }
  public get isLoaded() {
    return this.status === PreviewStatus.loaded;
  }
  document: string;
  optDisabled: boolean;
  pdfPage = 1;
  pdfUrl: string;
  private _sbAction: Subscription | undefined;
  constructor(
    private readonly logger: NGXLogger,
    private readonly bsModalRef: BsModalRef,
    private readonly setService: SetService,
    private readonly spinner: NgxSpinnerService,
    private readonly apiPostMessageService: ApiPostMessageService,
    private readonly touchlessService: TouchlessService,
    public readonly preview: PreviewService,
  ) {
    this._sbAction = this.touchlessService.onAction().subscribe(this._onAction.bind(this));
  }

  private _onAction(params: any) {
    switch (params.req) {
      case 'change-doc-page': {
        this.logger.log('Changing doc page...', params.status);
        if (params.status === 'next') {
          this.nextPage();
        } else if (params.status === 'previous') {
          this.previousPage();
        }
      }
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.setService.getDocumentPreviewUrl(this.document)
      .subscribe(response => {
        this.pdfUrl = response.data;
        this.statusSbj.next(PreviewStatus.loading);
        this.preview.isOpen = true;
      });
  }

  close() {
    this.apiPostMessageService.emitAction({
      req: 'action-preview',
      status: 'close'
    });
    this.bsModalRef.hide();
    this.preview.isOpen = false;
  }

  pdfLoaded() {
    this.spinner.hide();
    this.statusSbj.next(PreviewStatus.loaded);
  }

  nextPage() {
    (document.getElementById('next') as HTMLButtonElement).click();
  }

  previousPage() {
    (document.getElementById('previous') as HTMLButtonElement).click();
  }

  errorPdf() {
    const msg = 'Error loading pdf';
    this.logger.log(msg);
    alert(msg);
    this.statusSbj.next(PreviewStatus.error);
  }

  ngOnDestroy() {
    this._sbAction?.unsubscribe();
  }
}
