// tslint:disable-next-line:no-namespace
namespace _utils {
  export function isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
      /IEMobile/i,
      /Opera Mini/i,
      /Mobile/i,
      /mobile/i,
      /CriOS/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  export const apiPostMessageMethods = (onActionCb: any) => {
    const _methods = {
      action: (params) => onActionCb(params),
    };
    return _methods;
  };
}
export default _utils;
