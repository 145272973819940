import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TouchlessQrsComponent } from './touchless-qrs.component';

@NgModule({
  declarations: [TouchlessQrsComponent],
  imports: [CommonModule],
  exports: [TouchlessQrsComponent]
})
export class TouchlessQrsModule { }
