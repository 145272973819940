const enum AppRoutes {
  id = ':id',
  companyIdFriendlyUrl = ':companyId/:friendlyUrl',
  welcome = 'welcome',
  data = 'data',
  checkout = 'checkout',
  set = 'set',
  menuCategory = 'menu/:category',
  empty = '',
  viewDocSourceUrl = 'view-doc/:sourceUrl',
}
export default AppRoutes;

export function AllAppRoutes(): string[] {
  return [
    AppRoutes.id,
    AppRoutes.companyIdFriendlyUrl,
    AppRoutes.welcome,
    AppRoutes.data,
    AppRoutes.checkout,
    AppRoutes.set,
    AppRoutes.menuCategory,
    AppRoutes.empty,
    AppRoutes.viewDocSourceUrl,
  ];
}
